import React from "react"

const CustomMenuSection = props => {
  const { section } = props

  return (
    <div className="custom_menu_section">
      <p className="custom_section_title">{section.section_title}</p>
      {section.first_subsection_title && (
        <p className="custom_section_subtitle">
          {section.first_subsection_title}
        </p>
      )}
      {section.first_subsection_dishes && (
        <div style={{ marginBottom: 36 }}>
          {section.first_subsection_dishes.map((dish, index) => (
            <div key={index} className="dish_container">
              <div>
                <p style={{ fontSize: 18, fontWeight: 600 }}>
                  {dish?.dish_name ?? ""}
                </p>
                <p style={{ fontWeight: 300, whiteSpace: "pre-line" }}>
                  {dish?.dish_description ?? ""}
                </p>
              </div>
              <span
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  marginLeft: 12,
                  whiteSpace: "nowrap",
                }}
              >
                {dish.dish_price ?? ""}
              </span>
            </div>
          ))}
        </div>
      )}
      {section?.second_subsection_title && (
        <p className="custom_section_subtitle">
          {section?.second_subsection_title}
        </p>
      )}
      {section?.second_subsection_dishes && (
        <div style={{ marginBottom: 48 }}>
          {section?.second_subsection_dishes?.map((dish, index) => (
            <div key={index} className="dish_container">
              <div>
                <p style={{ fontSize: 18, fontWeight: 600 }}>
                  {dish?.dish_name ?? ""}
                </p>
                <p style={{ fontWeight: 300 }}>
                  {dish?.dish_description ?? ""}
                </p>
              </div>
              <span
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  marginLeft: 12,
                  whiteSpace: "nowrap",
                }}
              >
                {dish?.dish_price ?? ""}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomMenuSection
